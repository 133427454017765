import { LayoutHeader } from '@/components/LayoutHeader';
import LayoutHeaderProduct from '@/components/LayoutHeaderProduct';
import Logo from '@/components/Logo';
import { LogoVariant } from '@/components/LogoVariant';
import StickySidebar from '@/components/StickySidebar';
import { ID_TOTO } from '@/config/game/ids';
import { getGameByShortname } from '@/helpers/getGameByShortname';
import classnames from 'classnames';
import React from 'react';

export enum LayoutVariant {
  Default = 'default',
  Product = 'product',
  Home = 'home',
  FullWidth = 'full-width',
}

export interface LayoutProps {
  theme?: string;
  path: string;
  backHref?: string;
  backLabel?: string;
  variant?: LayoutVariant;
  date?: string;
  children?: React.ReactNode;
}

export class Layout extends React.Component<LayoutProps> {
  static Variant = LayoutVariant;
  static defaultProps: Partial<LayoutProps> = {
    variant: LayoutVariant.Default,
    theme: 'default',
  };

  private get variant(): LayoutVariant {
    return this.props.variant || LayoutVariant.Default;
  }

  private get isFullWidth(): boolean {
    return (
      this.variant === LayoutVariant.Home ||
      this.variant === LayoutVariant.FullWidth
    );
  }

  private renderHeader = (): JSX.Element | null => {
    const game = getGameByShortname(this.props.theme);
    if (this.variant === LayoutVariant.Home) {
      return null;
    }
    return this.variant === LayoutVariant.Product || game?.id === ID_TOTO ? (
      <LayoutHeaderProduct game={game} />
    ) : (
      <LayoutHeader game={game} />
    );
  };

  render() {
    const { theme, children, path, backLabel, backHref } = this.props;

    return (
      <article className={classnames('Layout', `Layout--${theme}`)}>
        {this.renderHeader()}
        <div className="Layout__body">
          {!this.isFullWidth && (
            <div className="Layout__sidebar">
              <StickySidebar
                path={path}
                backLabel={backLabel}
                backHref={backHref}
                offsetTop={80}
              />
            </div>
          )}
          <div
            className={classnames('Layout__content', {
              'Layout__content--full-width': this.isFullWidth,
            })}
            id="seiteninhalt"
          >
            {this.variant === LayoutVariant.Home && (
              <div className="Layout__logo">
                <Logo
                  variant={LogoVariant.Slogan}
                  title="Spielen beim Original!"
                />
              </div>
            )}
            {children}
          </div>
        </div>
      </article>
    );
  }
}
