import Button from '@/components/Button';
import { ButtonVariant } from '@/components/ButtonVariant';
import { Icon, IconVariant } from '@/components/Icon';
import { SidebarNavigationItem } from '@/components/SidebarNavigationItem';
import { SidebarNavItem } from '@/helpers/mapNavigationToSidebarItems';
import classnames from 'classnames';
import React from 'react';

interface SidebarNavigationLevelProps {
  id: string;
  root: string;
  items: SidebarNavItem[];
  current: string;
  level: number;
  overview?: string;
  parentTitle?: string;
  onClick: (id: string) => void;
  ariaLabel?: string;
}

export class SidebarNavigationLevel extends React.Component<SidebarNavigationLevelProps> {
  private focusableElements: HTMLElement[] = [];

  private handleOnItemClick = (id: string): void => {
    this.props.onClick(id);
  };

  private handleOnBackClick = (): void => {
    this.props.onClick(this.props.root);
  };

  private get isCurrent(): boolean {
    return this.props.id === this.props.current;
  }

  private renderBackButton = (): JSX.Element | null => {
    return this.props.level > 1 ? (
      <Button
        onClick={this.handleOnBackClick}
        variant={ButtonVariant.Text}
        className="SidebarNavigationLevel__back"
        iconPosition={Button.IconPosition.Left}
        tabIndex={0}
      >
        <Icon variant={IconVariant.ChevronLeft} />
        <span>Zurück</span>
      </Button>
    ) : null;
  };

  componentDidUpdate(prevProps: Readonly<SidebarNavigationLevelProps>): void {
    setTimeout(() => {
      if (this.isCurrent && prevProps.level !== this.props.level) {
        const currentLevelElement = document.querySelector(
          '.SidebarNavigationLevel--current'
        );

        if (!currentLevelElement) return;

        const focusableElements = Array.from(
          currentLevelElement.querySelectorAll('button, a')
        ) as HTMLElement[];

        if (focusableElements.length > 0) {
          focusableElements[0].focus();
        }

        this.focusableElements = focusableElements;
      }
    }, 100);
  }

  render(): JSX.Element {
    return (
      <>
        <div
          className={classnames(
            'SidebarNavigationLevel',
            `SidebarNavigationLevel--${this.props.level}`,
            {
              'SidebarNavigationLevel--current': this.isCurrent,
            }
          )}
          aria-label={
            this.props.ariaLabel || `Unternavigation ${this.props.parentTitle}`
          }
          role="navigation"
          tabIndex={0}
          inert={this.isCurrent ? undefined : ''}
        >
          {this.renderBackButton()}
          <ul className="SidebarNavigationLevel__list">
            {this.props.items
              .filter((item) => !item.link?.endsWith('#'))
              .map((item, i) => (
                <SidebarNavigationItem
                  key={i}
                  onClick={this.props.onClick}
                  {...item}
                />
              ))}
          </ul>
        </div>
        {this.props.items.map((item, i) => {
          return item.items ? (
            <SidebarNavigationLevel
              key={i}
              id={item.id}
              root={this.props.root}
              items={item.items}
              current={this.props.current}
              parentTitle={item.title}
              level={this.props.level + 1}
              onClick={this.handleOnItemClick}
            />
          ) : null;
        })}
      </>
    );
  }
}

export default SidebarNavigationLevel;
