import Logo from '@/components/Logo';
import { LogoVariant } from '@/components/LogoVariant';
import { GameConfig } from '@/config/game';
import { ID_TOTO_AW, ID_TOTO_EW } from '@/config/game/ids';
import classnames from 'classnames';
import HeaderGameTeaser from './HeaderGameTeaser';

export const LayoutHeader = ({ game }: { game?: GameConfig }) => (
  <header
    className={classnames(
      'LayoutHeader LayoutHeader--content LayoutHeader--no-campaign',
      `LayoutHeader--${game ? game.shortname : 'default'}`,
      { 'LayoutHeader--alternative': !!game && !!game.alternativeDesign }
    )}
  >
    <div className="LayoutHeader__background LayoutHeader__background--no-campaign">
      <div className="LayoutHeader__additionalBackground" />
    </div>
    <div
      className={classnames(
        'LayoutHeader__content',
        'LayoutHeader__content--no-campaign',
        {
          'LayoutHeader__content--default': !game,
        }
      )}
    >
      {game ? (
        game?.id === ID_TOTO_AW || game?.id === ID_TOTO_EW ? (
          <div className="GameHeader__container">
            <div
              className={classnames(
                'LayoutHeader__content LayoutHeader__content--toto'
              )}
            >
              <HeaderGameTeaser game={ID_TOTO_EW} link={null} />
              <HeaderGameTeaser game={ID_TOTO_AW} link={null} />
            </div>
          </div>
        ) : game.shortname === 'eurojackpot' ? (
          <Logo
            variant={LogoVariant.EurojackpotProductRefresh}
            title={game.title}
          />
        ) : (
          <>
            {game.shortname === 'lotto6aus49' && (
              <Logo
                variant={LogoVariant.Lotto6aus49Campaign}
                title={game.title}
              />
            )}
            {game.shortname !== 'lotto6aus49' && (
              <Logo variant={game.logo} title={game.title} />
            )}
            {game.shortname === 'rubbellose' && (
              <strong className="LayoutHeader__jackpot">
                {game.jackpotDesc}
              </strong>
            )}
          </>
        )
      ) : (
        <>
          <Logo variant={LogoVariant.Main} title="LOTTO.de" />
          <Logo
            variant={LogoVariant.Slogan}
            className="Logo--big"
            title="Spielen beim Original"
          />
        </>
      )}
    </div>
  </header>
);
